import { message } from "antd";
import axios from "axios";

const instance = axios.create({
    timeout: 30000,
    withCredentials: true,
});

instance.interceptors.request.use(
    config => {
        if (config.method?.toLocaleUpperCase() === 'GET') {
            if (!config.params) {
                config.params = {};
            }
            config.params['_'] = +new Date();
        }
        return config;
    }
);

instance.interceptors.response.use(
    response => {
        const { data, config } = response;
        if (config.headers.showMsg) {
            if (data.code === 200) {
                message.success(data.message);
            } else {
                message.error(data.requestId);
                message.error(data.message);
            }
        }
        return data;
    },
    (error) => {
        const {
            response: {
                data,
            },
        } = error;

        let errMsg;
        errMsg = data.message || '请求失败';
        message.error(errMsg);
        return Promise.reject(errMsg);
    }
);

export const http = instance;
export default http;