import { useState, useEffect, useRef, useMemo, useCallback } from "react"
import { Card, Form, Input, Button, Row, Col, message, ConfigProvider, Modal, Space } from "antd"
import http from "./request"
import "./App.css"
import "./global.less"
import { ExclamationCircleOutlined } from "@ant-design/icons"

const fLayout = {
	labelCol: {
		span: 6,
	},
	wrapperCol: {
		xl: { span: 14 },
		lg: { span: 18 },
		md: { span: 24 },
	},
}

const styls = {
    overflow: 'hidden',
    color:' #000000d9',
    'fontWeight': '500',
    'fontSize': '14px',
   	'lineHeight': 1.4,
	'marginLeft': '16px'
}

const App = () => {
	const [form] = Form.useForm()
	const originComing = useRef<any>() //避免重复触发图片请求
	const timer = useRef<any>() // 时间计时器
	const watchImgCode = Form.useWatch("imgCode", form) //监控图片验证码
	const watchPassWord = Form.useWatch("password", form) //监控密码
	const watchPhone = Form.useWatch("phone", form) //监控手机号
	const watchPhoneCheckCode = Form.useWatch("phoneCode", form) //监控手机验证码
	const [imgSrc, setImgSrc] = useState<any>("") //图片地址
	const [imgToken, setImgToken] = useState<any>("") //图片token 用于校验
	const [imgPass, setImgPass] = useState(false) //图片校验是否通过
	const [typeStatus, setTypeStatus] = useState("default") // default:发送验证码  check:还剩xx秒
	const [seconds, setSeconds] = useState<any>(null) // 倒计时秒数
	const [phoneToken, setPhoneToken] = useState<any>("") //手机号token 最终提交和校验都需要
	const [open, setOpen] = useState<boolean>(false); // 确认后的弹窗
	// 宽度自适应
	const [cardWidth, setCardWidth] = useState("60%") //卡片宽度
	const [formLayout, setFormLayout] = useState<any>(null) //表单样式

	const formItemLayout = formLayout === "horizontal" ? fLayout : null

	useEffect(() => {
		if (!originComing.current) {
			fetchImg()
		}
		originComing.current = true
	}, [])

	useEffect(() => {
		handleWidth()
		window.addEventListener("resize", () => {
			handleWidth()
		})
	}, [])

	const handleWidth = () => {
		const width = document.documentElement.clientWidth
		console.log("width==", width)
		console.log("width*0.6==", width * 0.6)

		if (width < 400 || width === 400) {
			setCardWidth("400px")
			setFormLayout("vertical")
		} else if (width < 680) {
			setCardWidth("100%")
			setFormLayout("vertical")
		} else if (width < 900) {
			setCardWidth("80%")
			setFormLayout("horizontal")
		} else {
			setCardWidth("60%")
			setFormLayout("horizontal")
		}
	}

	// 密码校验
	const validatePassWord = (rule: any, password: string) => {
		if (password) {
			// 是否包含小写
			const isLower = Number(/[a-z]/.test(password))
			// 是否包含大写
			const isUpper = Number(/[A-Z]/.test(password))
			// 是否包含数字
			const isNumber = Number(/[0-9]/.test(password))
			// 是否包含特殊字符
			// eslint-disable-next-line no-useless-escape
			const isSpecial = Number(/[!@#$%^&*()_\+\-\=]/.test(password))
			// 长度为6-16位，大写、小写、数字、特殊字符
			// eslint-disable-next-line no-useless-escape
			const basicReg = /^[a-zA-Z0-9!@#$%^&*()_\+\-\=]{6,16}$/
			if (isLower + isUpper + isNumber + isSpecial < 3 || !basicReg.test(password)) {
				return Promise.reject("大写、小写、数字、特殊字符占三种，长度为6－16位；特殊字符为!@#$%^&*()_+-=")
			}
		}
		return Promise.resolve()
	}

	// 再次输入密码校验
	const validateAgainPassWord = (rule: any, password: string) => {
		if (password && password !== watchPassWord) {
			return Promise.reject("两次密码需保持一致！")
		} else if (password && password === watchPassWord) {
			return validatePassWord(rule, password)
		}
		return Promise.resolve()
	}

	// 获取图片
	const fetchImg = () => {
		http
			.get(`/data/internal/genRandomCode?timestamps=${new Date().getTime()}`)
			.then((res) => {
				console.log("res==", res)
				if (res?.data?.img) {
					setImgSrc(res?.data?.img)
					setImgToken(res?.data?.token)
				} else {
					message.error("获取图片失败！")
				}
			})
			.catch((res) => {
				console.log("获取图片失败！")
			})
	}
	// 公用校验
	const commonCheck = (args: any, onSuccess: any, onErr: any) => {
		http
			.post(`/data/internal/validateCode`, args)
			.then((res) => {
				console.log("图片校验码结果", res)
				if (res?.data) {
					onSuccess()
				} else {
					onErr()
				}
			})
			.catch((res) => {
				console.log("校验不通过！")
			})
	}

	// 校验图片验证码
	const handleCheckImgcode = () => {
		if (watchImgCode) {
			commonCheck(
				{ code: watchImgCode, token: imgToken },
				() => {
					setImgPass(true)
				},
				() => {
					message.error("图片校验码不通过！")
					setImgPass(false)
				}
			)
		}
	}

	useEffect(() => {
		//如果设置倒计时且倒计时不为0
		if (seconds && seconds !== 0) {
			timer.current = setTimeout(() => {
				setSeconds((seconds: any) => seconds - 1)
			}, 1000)
		} else {
			setTypeStatus("default")
			clearTimeout(timer.current)
		}

		//清楚延时器
		return () => {
			clearTimeout(timer.current)
		}
	}, [seconds])

	// 判断输入的时候手机号还是邮箱
	const isInputEmail = useMemo(() => {
		const emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
		if (emailReg.test(watchPhone)) {
			return true
		} else {
			return false
		}
	}, [watchPhone]);

	// 发送手机验证码
	const handleSendMsg = () => {
		setTypeStatus("check")
		setSeconds(60)
		http
			.post(`/data/internal/sendMsg`, { 
				phone: isInputEmail ? undefined : watchPhone, 
				sendType: isInputEmail ? "mail" : "phone", 
				mail: isInputEmail ? watchPhone : undefined, 
				code: watchImgCode ? watchImgCode : undefined,
				token: imgToken
			})
			.then((res) => {
				console.log("发送手机验证码", res)
				if (res?.data?.token) {
					setPhoneToken(res?.data?.token || "")
				}
			})
			.catch((res) => {
				console.log("获取手机验证码失败！")
			})
	}

	// 提交
	const handleSubmit = () => {
		form
			.validateFields()
			.then((res) => {
				commonCheck(
					{ code: watchPhoneCheckCode, token: phoneToken },
					() => {
						const params = {
							password: btoa(res.password),
							phone: isInputEmail ? undefined : watchPhone,
							mail: isInputEmail ? watchPhone : undefined,
							token: phoneToken,
							code: watchPhoneCheckCode,
						}
						fetchSubmit(params)
					},
					() => {
						message.error(isInputEmail ? "邮箱校验码不通过！" : "手机校验码不通过！")
					}
				)
			})
			.catch((error) => {
				console.log("err", error)
			})
	}

	// 提交请求
	const fetchSubmit = (args: any) => {
		http
			.post(`/data/internal/reset`, args)
			.then((res: any) => {
				if (res?.code === 200) {
					// message.success("密码重置成功！",10)
					setOpen(true)
					form?.resetFields()
				} else {
					message.error(`密码重置失败！${res?.message}`)
				}
			})
			.catch((res) => {
				console.log("重置失败！")
			})
	}

	const itemMarginBottom = useMemo(() => {
		return formLayout === "horizontal" ? 'defaulMargin' : 'smallMaegin'
	}, [formLayout]);
	const getParamsFromURL = useMemo(() => {
		const url = window.location.href;
		const urlList = url.split('?');
		const urlStr = urlList?.slice(1)?.join('?');
		return {
			goto: urlStr?.split('=')[0],
			redirectUrl: urlStr?.split('=')?.slice(1)?.join('=')
		}
	}, []);
	const closeResetModal = useCallback(() => {
		setOpen(false);
		if (getParamsFromURL?.goto === 'redirectUrl' && getParamsFromURL?.redirectUrl) {
			// 创建a标签
			let aLink = document.createElement('a');
			aLink.href = getParamsFromURL?.redirectUrl;
			document.body.appendChild(aLink);
			aLink.click();
		}
	}, [getParamsFromURL]);
	return (
		<div>
			<ConfigProvider
				// theme={{
				// 	components: {
				// 		Form: {
				// 			itemMarginBottom: formLayout === "horizontal" ? 24 : 14,
				// 		},
				// 	},
				// }}
			>
				<Card title="重置密码" style={{ width: cardWidth, margin: formLayout === "horizontal" ? "7% auto" : "0 auto", minWidth: 400 }} type="inner" bordered={formLayout === "horizontal"}>
					<Form 
						form={form}
						{...formItemLayout}
						layout={formLayout}
					>
						<Form.Item
							label="手机号码/邮箱"
							rules={[
								{ required: true, message: "请输入手机号码/邮箱" },
								{
									validator: (rule: any, value: string) => {
										const reg = /^(1[3-9]\d{9})$/;
										const emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
										if (value && !reg.test(value) && !emailReg.test(value)) {
											return Promise.reject("请输入正确手机号/邮箱")
										}
										return Promise.resolve()
									},
								},
							]}
							name="phone"
							className={itemMarginBottom}
						>
							<Input placeholder="请输入手机号码/邮箱" />
						</Form.Item>
						<Form.Item className={itemMarginBottom} label="新密码" rules={[{ required: true, message: "请输入新密码" }, { validator: validatePassWord }]} name="password">
							<Input.Password placeholder="请输入新密码" />
						</Form.Item>
						<Form.Item className={itemMarginBottom} label="再次输入新密码" rules={[{ required: true, message: "请输入密码" }, { validator: validateAgainPassWord }]} name="againPassword" dependencies={["password"]}>
							<Input.Password placeholder="请再次输入新密码" />
						</Form.Item>
						<Form.Item className={itemMarginBottom} label="图片验证码" required>
							<Row gutter={16}>
								<Col span={10}>
									<Form.Item rules={[{ required: true, message: "请输入图片验证码" }]} name="imgCode" noStyle>
										<Input placeholder="请输入图片验证码" onBlur={handleCheckImgcode} />
									</Form.Item>
								</Col>
								<Col span={14}>
									<img src={imgSrc} alt="图片加载中…" style={{ width: 100 }} />
									<Button
										type="link"
										style={{ verticalAlign: "top" }}
										onClick={() => {
											fetchImg()
											setImgPass(false)
											form.setFieldsValue({ imgCode: null })
										}}
									>
										刷新
									</Button>
								</Col>
							</Row>
						</Form.Item>
						<Form.Item className={itemMarginBottom} label={ isInputEmail ? "邮箱验证码" : "手机验证码"} required>
							<Row gutter={16}>
								<Col span={10}>
									<Form.Item
										rules={[
											{ required: true, message: isInputEmail ? "请输入邮箱验证码" : "请输入手机验证码" },
											{
												validator(rule, value, callback) {
													const reg = /^[0-9]{6,6}$/
													if (value && !reg.test(value)) {
														return Promise.reject("请输入6位数字验证码")
													}
													return Promise.resolve()
												},
											},
										]}
										name="phoneCode"
										noStyle
									>
										<Input placeholder={isInputEmail ? "请输入邮箱验证码" : "请输入手机验证码"} />
									</Form.Item>
								</Col>
								<Col span={6}>
									{typeStatus === "default" ? (
										<Button type="primary" onClick={handleSendMsg} disabled={!(imgPass && watchPhone)}>
											发送验证码
										</Button>
									) : (
										<Button disabled>还剩{seconds}秒</Button>
									)}
								</Col>
							</Row>
						</Form.Item>
						<div style={{ textAlign: "center" }}>
							<Button type="primary" onClick={handleSubmit}>
								确认
							</Button>
						</div>
					</Form>
					<Modal
						open={open}
						onCancel={closeResetModal}
						footer={
							<Space>
								<Button onClick={closeResetModal}>关闭</Button>
								<Button danger onClick={closeResetModal}>确定</Button>
							</Space>
						}
					>
						<p>
							<ExclamationCircleOutlined style={{color: "#faad14", fontSize: '22px'}}/>
							<span style={styls}>
								您的密码已重置成功！
							</span>
						</p>
					</Modal>
				</Card>
			</ConfigProvider>
		</div>
	)
}

export default App
